import { useWebview } from '~/stores/webview';
import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware(async () => {
    // Webview
    const webViewStore = useWebview();
    const { setWebview } = webViewStore;

    const authStore = useAuthStore();
    const { handleUserData } = authStore;

    // useRequestHeaders works only on serverside
    // Grab values + assign to useState to use them also on client side
    if (process.server) {
        // Get headers
        const headers = await useRequestHeaders();
        const webview = ref(headers['x-content-only']);
        const correlationId = ref(headers['x-correlation-id']);
        const bearerToken = ref(headers.authorization);

        if (bearerToken.value?.includes('Bearer ')) {
            bearerToken.value = bearerToken.value.split('Bearer ')[1];
        }

        useState('state_bearerToken', () => bearerToken.value);
        useState('state_correlationId', () => correlationId.value);
        useState('state_webview', () => webview.value);
    }

    // Get data from useState
    const webview = useState('state_webview').value;
    const correlationId = useState('state_correlationId').value;
    const bearerToken = useState('state_bearerToken').value;

    // Update webview
    setWebview(webview);

    // If auth page
    if (bearerToken) {
        // Validate bearer token from webview to validate endpoint
        // Send bearerToken as request header
        const { data } = await useFetch('/api/validate-token',
            {
                headers: {
                    Authorization: bearerToken
                }
            }
        );

        const { oid, isValid, reason } = data.value;

        // If token is valid
        if (isValid) {
            // Set useState oid
            useState('state_userOid', () => oid);

            // Fetch data from consumer api
            await handleUserData({
                method: 'GET',
                correlationId,
                bearerToken,
                oid
            });
        } else {
            // Token ist not valid: reset useState variables
            useState('state_bearerToken', () => null);
            useState('state_correlationId', () => null);
            useState('webviewFailed', () => reason);
        }
    }
});
